<template>
    <div>
        <Navbar page="Relatório de evidências" link="/entregaveis" nameLink="Entregáveis" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="text-3xl mb-5">Relatório de evidências</h1>
            <a v-if="link" :href="link" target="_blank" class="text-base text-underline text-blue-500">Visualizar relatório: {{ link}}</a>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-4">
                    <label for="tipo_relatorio" class="block text-sm font-medium"> 
                        Setor
                    </label>
                    <select v-model="setor" @change="getBySetor" for="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option default :value="null">Todos</option>
                        <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                    </select>
                </div>
                <div class="col-span-12 md:col-span-4">
                    <label for="tipo_relatorio" class="block text-sm font-medium"> 
                        Atividade
                    </label>
                    <select v-model="atividade" for="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option default :value="null">{{setor ? 'Todas' : 'Selecione um setor'}}</option>
                        <option v-for="at in atividades" :key="at._id" :value="at._id">{{ at.nome }}</option>
                    </select>
                </div>
               
                <div class="col-span-12 md:col-span-2">
                    <label class="block text-sm font-medium"> 
                        Download
                    </label>

                    <a :href="`${this.url_api}/v1/entregaveis/evidenciasdocx/${$store.state.empresa._id}/${setor}/${atividade}?token=${this.$store.state.token}`" target="_blank" class="block mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            setores: [],
            atividades: [],

            setor: null,
            atividade: null,
        }
    },
    methods: {
        async getBySetor() {
            this.atividade = null
            this.atividades = []
            const reqat = await this.$http.post(`/v1/atividades/list`, { all: true, setor: this.setor });
            this.atividades = reqat.data.data;
        }
    },
    async beforeMount() {
        const setoresReq = await this.$http.post(`/v1/setores/list`, { all: true });
        this.setores = setoresReq.data.data;
    }
}
</script>